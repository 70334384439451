import axios from "axios"
import { useEffect, useState } from "react"
import { API_ROUTES } from "../utils/constants"
import Edit from "./Edit"
import styled from "styled-components"
import { colors, MediaMobile } from "../utils/style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquarePlus, faPenToSquare, faSquareXmark } from "@fortawesome/free-solid-svg-icons"

// #region CSS
const ListMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 800px;
    ${MediaMobile} {
        width: 100%;
    }
`

const ListContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
`

const List = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    margin-right: 100px;
    & h3 {
        margin: 0;
        font-weight: normal;
        font-size: 14px;

    }
    ${MediaMobile} {
        margin-right: 20px;
    }
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    & p {
        margin: 0;
        color: ${colors.tertiary};
        font-weight: bold;
        font-size: 14px;

    }
`

const FormContainer = styled.form`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    justify-content: space-between;
    gap: 20px;
`

const FormList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

`

const Input = styled.input`
    border: transparent;
    padding: 5px 10px;
    width: 100%;
    outline: none;
    box-shadow: 3px 3px 5px ${colors.backgroundThree};
`

const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    ${MediaMobile} {
        align-items: flex-end;
    }

`

const IconButton = styled.button`
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
`

const FontIcon = styled(FontAwesomeIcon)`
    color: ${colors.tertiary};
    font-size: 18px;
`
// #endregion CSS

function PricesDetails() {
    const user = localStorage.getItem("token") 
    
    const initValue = {title: "", time: "", price: ""}

    const [input, setInput] = useState(initValue)
    const [inputList, setInputList] = useState([])
    const [updateState, setUpdateState] = useState(null)
    const [updateUI, setUpdateUI] = useState(false)

    const handleChange = (e) => {
        const {value, name} = e.target
        setInput((preve) => {
            return{...preve, [name] : value}
        })
    }

    // Gestion de l'ajout d'une ligne
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await axios.post(API_ROUTES.PRICES, {...input}, {headers: {Authorization: `Bearer ${user}`}})
                setInput(initValue)
                setUpdateUI((prevState) => !prevState)
        } catch (err) {
            console.error(err)
        }
    }

    // Récupération des données
    const getFetchData = async () => {
        const getElement = await axios.get(API_ROUTES.PRICES)
            setInputList(getElement.data)
    }

    
    // Modification d'une ligne
    const handleEdit = async (id) => {
        setUpdateState(id)
    }

    const handleUpdate = async (e) => {
        e.preventDefault()
        await axios.put(`${API_ROUTES.PRICES}/${updateState}`, inputList.find((item) => item._id === updateState), {headers: {Authorization: `Bearer ${user}`}})
            setUpdateState(null)
            setInput(initValue)
    }
    
    // Suppression d'une ligne
    const handleDelete = async (id) => {
        await axios.delete(`${API_ROUTES.PRICES}/${id}`, {headers: {Authorization: `Bearer ${user}`}})
            setUpdateUI((prevState) => !prevState)
    }

    useEffect(() => {
        getFetchData()
    }, [updateUI])

    return (
        <ListMainContainer>
            {inputList.map((item, index) =>
                updateState === item._id ? 
                <Edit 
                    item={item} 
                    inputList={inputList} 
                    setInputList={setInputList} 
                    handleUpdate={handleUpdate}
                /> :
                <ListContainer key={`price-${index}-${item._id}`} >
                    <List>
                        <h3>{item.title}</h3>
                        <InfoContainer>
                            <p>{item.time} min</p>
                            <p>{item.price} €</p>
                        </InfoContainer>
                    </List>
                    {user && (
                    <IconContainer>
                        <IconButton onClick={() => handleEdit(item._id)}><FontIcon icon={faPenToSquare} /></IconButton>
                        <IconButton onClick={() => handleDelete(item._id)}><FontIcon icon={faSquareXmark} /></IconButton>
                    </IconContainer>
                    )}
                </ListContainer>
            )}
            {user && (
                <FormContainer onSubmit={handleSubmit}>
                    <Input type="text" name="title" value={input.title} placeholder="Intitulé de la prestation" onChange={handleChange}/>
                    <FormList>
                        <Input type="text" name="time" value={input.time} placeholder="Durée" onChange={handleChange}/>
                        <Input type="text" name="price" value={input.price} placeholder="Prix" onChange={handleChange}/>
                        <IconButton type="submit"><FontIcon icon={faSquarePlus} /></IconButton>
                    </FormList>
                </FormContainer>
            )}

        </ListMainContainer>

    )
}

export default PricesDetails