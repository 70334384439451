import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { colors, MediaMobile } from "../utils/style/GlobalStyle"
import Gallery from "../components/Gallery"
import { useState, useEffect } from "react"
import { API_ROUTES } from "../utils/constants"
import axios from "axios"
import ModalImage from "../components/ModalImage"

// #region CSS
const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin: 20px 40px;
    ${MediaMobile} {
        margin: 0;
        padding: 5px;
        gap: 10px;
    }
`

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    position: relative;
`

const MainTitle = styled.h1`
    margin: 0;
    color: ${colors.title};
    ${MediaMobile} {
        font-size: 20px;
    }

`

const ModificationButton = styled.button`
    position: absolute;
    border: none;
    background-color: transparent;
    color: ${colors.tertiary};
    right: -130px;
    cursor: pointer;
`
// #endregion CSS

function GalleryPage() {
    const user = localStorage.getItem("token") 

    const [openModal, setOpenModal] = useState(false)
    const [imageObject, setImageObject] = useState("")
    const [inputList, setInputList] = useState([])
    const [updateUI, setUpdateUI] = useState(false)

    // Ajout d'une photo
    const handleSubmit = async (e) => {
        e.preventDefault()
        await axios.post(API_ROUTES.IMAGES, {image: imageObject})
        setImageObject("")
        setOpenModal(false)
        setUpdateUI((prevState) => !prevState)
    }


    // Récupération des données
    const getFetchData = async () => {
        const getElement = await axios.get(API_ROUTES.IMAGES)
            setInputList(getElement.data)
    }

    // Suppression d'une photo
    const handleDelete = async (id) => {
        await axios.delete(`${API_ROUTES.IMAGES}/${id}`)
            setUpdateUI((prevState) => !prevState)
    }
    
    
    useEffect(() => {
        getFetchData()
    }, [updateUI])


    return(
        <>
            <MainContainer>
                <TitleContainer>
                    <MainTitle>Galeries photos</MainTitle>
                    {!user ? 
                        null : 
                        <ModificationButton onClick={() => setOpenModal(true)}>
                            <FontAwesomeIcon icon={faPenToSquare} /> ajouter
                        </ModificationButton>
                    }
                </TitleContainer>
                <Gallery
                    inputList={inputList}
                    handleDelete={handleDelete}
                    user={user}
                />

            </MainContainer>
            {openModal && (
                <ModalImage 
                    setOpenModal={setOpenModal}
                    handleSubmit={handleSubmit}
                    setImageObject={setImageObject}
                    imageObject={imageObject}
                />
                
            )}
        </>
    
    )
}

export default GalleryPage