import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import { colors, MediaMobile } from "../utils/style/GlobalStyle"
import axios from "axios"
import { API_ROUTES } from "../utils/constants"

// #region CSS
const SeeTimeSheetContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin: 35px;
    ${MediaMobile} {
        gap: 10px;
        margin: 10px;
    }

`

const TimeSheet = styled.label`
    position : relative;
    text-align: center;
    border-radius: 5px;
    padding: 10px 5px;
    width: 80px;
    height: 15px;
    font-size: 13px;
    background-color: ${colors.backgroundThree};
    &: hover{
        background-color: ${colors.background};
        color: ${colors.primary};
        cursor: pointer;
    }
    &.selected {
        background-color: ${colors.background};
        color: ${colors.primary};
    }
    ${MediaMobile} {
        padding: 6px 3px;
        font-size: 12px;
        width: 60px;
    }
`

const TimeSheetInput = styled.input`
    position: absolute;
    opacity: 0;
`

const AddTimeSheetContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    gap: 20px;
`

const BarContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 50px;
    padding: 5px 10px;
    width: 300px;
    box-shadow: 0 2px 3px ${colors.shadow};
    position: relative;
`

const BarInput = styled.input`
    outline: none;
    width: 100%;
    background-color: transparent;
    border: 0;
`

const FontIcon = styled(FontAwesomeIcon)`
    color: ${colors.tertiary};
    cursor: pointer;

`

const ListContainer = styled.div`
    position: absolute;
    z-index: 1;
    transform: translate(-50px, 27px);
    box-shadow: 0 2px 3px ${colors.shadow};
    background-color: ${colors.primary};
    border-radius: 0 0 3px 3px;
    margin-left: 30px;
    padding: 10px;
    overflow-y: auto;
    width: 200px;
    height: 80px;
`

const ListText = styled.div`
    cursor: pointer;
    font-size: 13px;
`


const TagsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 25px;
`

const Tags = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 10px 5px;
    width: 80px;
    height: 15px;
    gap: 10px;
    font-size: 13px;
    &: hover{
        background-color: ${colors.backgroundThree};
        cursor: pointer;
    }
    
`
// #endregion CSS

const timeSheet = [
    "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", 
    "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", 
    "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30"
]

function HourSlot ({ date, name, onChange, selectedObjects, setSelectedObjects}) {
    const [isOpen, setIsOpen] = useState(false)
    const [tags, setTags] = useState([])
    const [deletedHour, setDeletedHour] = useState([])
    const [updateUI, setUpdateUI] = useState(false)

    const user = localStorage.getItem("token") 


    // Ajout d'un tag dans TagsContainer
    async function addTag(tag) {
        await axios.delete(`${API_ROUTES.BOOKING}/${date}/${tag}`, {headers: {Authorization: `Bearer ${user}`}})
            setDeletedHour((prevDeletedHour) => prevDeletedHour.filter((hour) => hour !== tag))
            setUpdateUI((prevState) => !prevState)
    } 
    
    // Suppression du tag dans ListContainer
    async function deletedTag(tag) {
        const postDate = await axios.post(`${API_ROUTES.BOOKING}/${date}/${tag}`, {date, hour: tag}, {headers: {Authorization: `Bearer ${user}`}})
            setTags((prevTags) => prevTags.filter((hour) => hour !== tag))
            setDeletedHour((prevDeletedHour) => [...prevDeletedHour, tag])
            setUpdateUI((prevState) => !prevState)
            console.log("postDate", postDate)
    }

    // Récupération des données
    const getFetchData = async () => {
        const getElement = await axios.get(`${API_ROUTES.BOOKING}/${date}`)
        setDeletedHour(getElement.data)
        const availableHours = timeSheet.filter((hour) => !getElement.data.includes(hour))
        setTags(availableHours)
    }

    // Gestion des utilisateurs
    const deletedHourStrings = deletedHour.map(hourObj => hourObj.hour)
    const sortHours = deletedHourStrings.sort((a, b) => {
        const [hourA, minA] = a.split(":").map(Number)
        const [hourB, minB] = b.split(":").map(Number)
        if (hourA !== hourB) return hourA - hourB
        return minA - minB
    })
    const remainingHours = tags.filter(hour => !deletedHourStrings.includes(hour))

    const handleSelectProduct = (e) => {
        const selectedObject = remainingHours.find((i) => i === e.target.value)
        setSelectedObjects(selectedObject)
        onChange(selectedObject)
    }

        
    useEffect(() => {
        getFetchData()
    }, [date, updateUI])

    return (
        <>
            {!user ? 
                <SeeTimeSheetContainer>
                    {!date ? (
                        tags.map((item, index) => 
                            <TimeSheet key={`tagItem-${index}-${item}`}>
                                {item}
                            </TimeSheet>
                        )
                    ) : (
                        remainingHours.map((item, index) => 
                            <TimeSheet 
                                key={`tagItem-${index}-${item}`}
                                className={selectedObjects === item ? "selected" : "" } 
                                name={name}
                            >
                                {item}
                                <TimeSheetInput 
                                    type="radio" 
                                    value={item} 
                                    onChange={handleSelectProduct}
                                    checked={selectedObjects === item}
                                />
                            </TimeSheet>
                        )
                    )}
                </SeeTimeSheetContainer>            
            :
                <AddTimeSheetContainer>
                    <BarContainer>
                        <BarInput placeholder="-- Sélectionner un horaire" />
                        {isOpen === false ? <FontIcon icon={faChevronUp} onClick={() => setIsOpen(true)}/> : <FontIcon icon={faChevronDown} onClick={() => setIsOpen(false)}/>}
                    </BarContainer>
                    {isOpen && 
                    <ListContainer >
                        {sortHours.map((item, index) =>
                            <ListText key={`tagItem-${index}-${item}`} onClick={() => addTag(item)}>{item}</ListText>
                        )}
                    </ListContainer>
                    }
                    <TagsContainer>
                        {!date ? (
                            tags.map((item, index) => 
                                <Tags key={`tagItem-${index}-${item}`}>
                                    {item}
                                </Tags>
                        )
                        ) : (
                            remainingHours.map((item, index) => 
                                <Tags key={`tagItem-${index}-${item}`}>
                                    {item}
                                    <FontIcon icon={faXmark} onClick={() => deletedTag(item)}/>
                                </Tags>
                            )
                        )}
                    </TagsContainer>
                </AddTimeSheetContainer>
            }
        </>
    
    )
}

export default HourSlot