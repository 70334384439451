import styled from "styled-components"
import axios from "axios"
import { API_ROUTES } from "../utils/constants"
import { useState, useEffect } from "react"
import { Calendar, dateFnsLocalizer } from "react-big-calendar"
import format from "date-fns/format"
import parse from "date-fns/parse"
import startOfWeek from "date-fns/startOfWeek"
import getDay from "date-fns/getDay"
import "react-big-calendar/lib/css/react-big-calendar.css"
import fr from "date-fns/locale/fr"
import { colors } from "../utils/style/GlobalStyle"

// #region CSS
const CalendarComposant = styled(Calendar)`
& .rbc-btn-group {
    box-shadow: 3px 3px 5px ${colors.boxShadow};
    border-radius: 5px;
}
& .rbc-btn-group button{
    border: 1px solid ${colors.backgroundThree};
    &: hover {
        background-color: ${colors.backgroundTwo};
        color: ${colors.primary};
        border: 1px solid ${colors.backgroundTwo};
    }
}
& .rbc-toolbar button.rbc-active {
    box-shadow: none;
    background-color: ${colors.backgroundThree};
    border-color: ${colors.backgroundThree};
}
& .rbc-month-header .rbc-header{
    background-color: ${colors.background};
    color: ${colors.primary};
    padding: 10px;
    border-bottom: none;
}
& .rbc-timeslot-group  {
    min-height: 120px;
}
& .rbc-event {
    background-color: ${colors.backgroundTwo};
    font-size: 14px;
}
& .rbc-today {
    background-color: ${colors.backgroundThree};
}
& .rbc-off-range-bg{
    background-color: transparent;
    color: ${colors.shadow};
}
& .rbc-show-more {
    color: ${colors.quarter};
}
& .rbc-day-slot .rbc-event {
    min-height: 60px;
    font-size: 12px;
    border: none;
}
& .rbc-agenda-table .rbc-header {
    background-color: ${colors.background};
    color: ${colors.primary};
    padding: 10px;
    border-bottom: none;
}
`

const messages = {
    allDay: "Tous les jours",
    previous: "Précédent",
    next: "Suivant",
    today: "Aujourd'hui",
    month: "Mois",
    week: "Semaine",
    day: "Jour",
    agenda: "Agenda",
    date: "Date",
    time: "Heure",
    event: "Evenement",
}
// #endregion CSS

function CalendarPage () {
    const user = localStorage.getItem("token") 

    const [data, getData] = useState([])
    const [updateUI, setUpdateUI] = useState(false)
    
    // Récupération des données des RDV
    const getFetchData = async () => {
        const getElement = await axios.get(API_ROUTES.BOOKING)
        getData(getElement.data)    
    }

    // Suppression d'une ligne
    const handleDelete = async (id) => {
        if (window.confirm('Etes-vous sûr de vouloir supprimer?')) {
            await axios.delete(`${API_ROUTES.BOOKING}/${id}`, {headers: {Authorization: `Bearer ${user}`}})
            getData(data.filter((e) => e._id !== id))
            setUpdateUI((prevState) => !prevState)
        }
    }

    // Définition des éléments du calendrier
    const locales = {
        fr: fr
      }
      const localizer = dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales
      })
    
    const events = data.map((item) => ({
        id: item._id,
        title: ` ${item.name} - ${item.phone} - ${item.email}`,
        start: new Date(`${item.startDate}`),
        end: new Date(`${item.endDate}`),
    }))

    useEffect(() => {
        getFetchData()
    }, [updateUI])
    

    return (
        <div>
            <CalendarComposant 
                min={new Date(0, 0, 0, 8, 0, 0)}
                max={new Date(0, 0, 0, 22, 0, 0)}
                localizer={localizer} 
                events={events} 
                startAccessor="start"
                endAccessor="end"
                style={{ height: 600, margin: "50px" }}
                culture="fr"
                messages={messages}
                onSelectEvent={(event) => handleDelete(event.id)}
            />
        </div>
    )
}

export default CalendarPage