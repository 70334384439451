import styled from "styled-components"
import { colors } from "../utils/style/GlobalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faPenToSquare, faSquareCheck } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import { API_ROUTES } from "../utils/constants"
import ModalImage from "./ModalImage"
import UniquePhoto from "./UniquePhoto"
import { MediaMobile } from "../utils/style/GlobalStyle"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// #region CSS
const MainContainer = styled.div`
    position: relative;
    width: 1000px;
    ${MediaMobile} {
        width: 100%;
    }
`

const SliderContainer = styled(Slider)`
    &.slick-slider {
        gap: 20px;
        & .slick-next, .slick-prev {
            right: 0;
        }
        ${MediaMobile} {
            margin: 0;
        }
    }
    & .slick-list .slick-track .slick-slide div {
        display: flex;
        flex-direction: row;
        & .carousel-container {
            display: flex;
            flex-direction: column;
            padding: 10px;
        }
    }
    & .slick-dots li.slick-active button:before {
        color: ${colors.button};
    }
    & .slick-dots li button:before {
        color: ${colors.button};
    }    
`

const GalleryLink = styled(Link)`
    text-decoration: none;
    color: ${colors.quarter};
    &: hover{
        text-decoration: underline;
    }
    ${MediaMobile} {
        font-size: 14px;
        padding-left: 13px;
        text-decoration: underline;
    }
`

const ChevronFontIcon = styled(FontAwesomeIcon)`
    font-size: 14px;
    margin-left: 5px;
    ${MediaMobile} {
        font-size: 10px;
    }
`

const DetailContainer = styled.div`
    width: 100%;
    & h2 {
        color: ${colors.title};
        ${MediaMobile} {
            font-size: 16px;
            margin: 0;
        }
    }
    & div, form {
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: 100%;
        & p {
            margin: 0;
            ${MediaMobile} {
                font-size: 13px;
            }
        }
    }
`

const Input = styled.input`
    border: transparent;
    padding: 5px 10px;
    width: 100%;
    outline: none;
    border-radius: 2px;
    box-shadow: 0 2px 3px ${colors.shadow};
`


const IconButton = styled.button`
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
`

const FontIcon = styled(FontAwesomeIcon)`
    color: ${colors.tertiary};
    font-size: 18px;
`
// #endregion CSS


function PrestationDetail () {
    const user = localStorage.getItem("token") 

    const initValue = {description: "", image: ""}

    const [input, setInput] = useState(initValue)
    const [inputList, setInputList] = useState([])
    const [updateState, setUpdateState] = useState(null)
    const [imageObject, setImageObject] = useState('')
    const [currentIndex, setCurrentIndex] = useState(0)
    const [openModal, setOpenModal] = useState(false)
    const [updateUI, setUpdateUI] = useState(false)



    // Récupération des données
    const getFetchData = async () => {
        const getElement = await axios.get(API_ROUTES.PRICES)
            setInputList(getElement.data)
    }

    // Modification des champs description / image
    const handleChange = (e) => {
        const {value, name} = e.target
        const newInput = {...input, [name]: value}
        setInput(newInput)
        const newInputList = inputList.map((i) => (
            i._id === inputList[currentIndex]._id ? {...i, [name] : value} : i
        ))
        setInputList(newInputList)
    }

    const handleEdit = async (id) => {
        setUpdateState(id)
        const newIndex = inputList.findIndex((item) => item._id === id)
        setCurrentIndex(newIndex)
    }

    // Open Modal
    const triggerOpenModal = (id) => {
        setUpdateState(id)
        setOpenModal(true)
    }
    
    const handleDeleteImage = async (e) => {
        e.preventDefault()
        await axios.put(`${API_ROUTES.PRICES}/${inputList[currentIndex]._id}`, {image: ''}, {headers: {Authorization: `Bearer ${user}`}})
        const newInput = inputList.map((i) => (
            i._id === inputList[currentIndex]._id ? {...i, image: ''} : i
        ))
        setInputList(newInput)
        setUpdateUI((prevState) => !prevState)
    }

    const handleUpdate = async (e, field) => {
        e.preventDefault()
        if (field === "image") {
            await axios.put(`${API_ROUTES.PRICES}/${updateState}`, {image: imageObject }, {headers: {Authorization: `Bearer ${user}`}})
            setImageObject('')
        } else if (field === "description") {
            await axios.put(`${API_ROUTES.PRICES}/${updateState}`, { description: inputList[currentIndex].description }, {headers: {Authorization: `Bearer ${user}`}})
        }
            setUpdateState(null)
            setOpenModal(false)
            setUpdateUI((prevState) => !prevState)
    }

    useEffect(() => {
        getFetchData()
    }, [updateUI])

    const settings = {
        swipe: true,
        swipeToSlide: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        fade: true,
        responsive: [
          {
            breakpoint: 768,
            dots: true,
            settings: {
              swipe: true,
              swipeToSlide: true
            }
          }
        ]
      }

    return (
        <MainContainer>
            {inputList.length > 0 && (
            <SliderContainer {...settings}>
                {inputList.map((item, index) => (
                    <>
                        <div className="carousel-container">
                            <UniquePhoto 
                                item={item._id}
                                picture={item.image}
                                alt="relative à la description"
                                setOpenModal={triggerOpenModal}
                                handleDeleteImage={handleDeleteImage}
                                user={user}
                            />
                            <GalleryLink to="/gallery">Galerie Photos<ChevronFontIcon icon={faChevronRight} /></GalleryLink>
                        </div>
                        <DetailContainer className="carousel-container">
                            <h2>{item.title}</h2>
                            {updateState === item._id ? 
                                <form onSubmit={(e) => handleUpdate(e, "description")}>
                                    {user && (<IconButton type="submit"> <FontIcon icon={faSquareCheck} /></IconButton>)}    
                                    <Input type="text" name="description" value={item.description} onChange={handleChange}/>
                                </form>
                            : 
                                <div>
                                    {user && (<IconButton onClick={() => handleEdit(item._id)}> <FontIcon icon={faPenToSquare} /></IconButton>)}
                                    <p>{item.description}</p>
                                </div>
                            }
                        </DetailContainer>       
                    </>
                ))}
            </SliderContainer>
            )}
            {openModal && (
                <ModalImage 
                    setOpenModal={setOpenModal}
                    setImageObject={setImageObject}
                    imageObject={imageObject}
                    handleSubmit={(e) => handleUpdate(e, "image")}
                />
                
            )}

        </MainContainer>

    )
}

export default PrestationDetail