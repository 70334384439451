import { Link } from "react-router-dom"
import Banner from "../components/Banner"
import styled from "styled-components"
import { colors } from "../utils/style/GlobalStyle"
import PrestationDetail from "../components/PrestationDetail"
import { MediaMobile } from "../utils/style/GlobalStyle"

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin: 20px;
    ${MediaMobile} {
        margin: 0;
        gap: 10px;
    }
`

const InformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 30px;
    ${MediaMobile} {
        padding: 10px 0;
        gap: 10px;
    }
`

const TitleContainer = styled.h1`
    margin: 0;
    color: ${colors.title};
    ${MediaMobile} {
        font-size: 20px;
    }

`

// const ModificationButton = styled.button`
//     position: absolute;
//     border: none;
//     background-color: transparent;
//     color: ${colors.tertiary};
//     right: -130px;
//     cursor: pointer;
// `

const PriceLink = styled(Link)`
    background-color: ${colors.button};
    color: ${colors.primary};
    text-decoration: none;
    border-radius: 5px;
    padding: 16px 50px;
    ${MediaMobile} {
        font-size: 14px;
        padding: 8px 25px;
        margin-top: 20px;
        border-radius: 3px;
    }

`


// const DetailContainer = styled.div`
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//     width: 1000px;
//     margin: 20px;
//     padding: 20px;

// `

function HomePage() {

    return(
        <MainContainer>
            <Banner/>
            <InformationContainer>
                <TitleContainer>Mes prestations</TitleContainer>
                <PrestationDetail/>
                <PriceLink to="/prices">Voir les tarifs</PriceLink>
            </InformationContainer>
            {/* <InformationContainer>
                <TitleContainer>
                    <h1>A propos de moi</h1>
                     <ModificationButton><FontAwesomeIcon icon={faPenToSquare} /> modifier</ModificationButton>
                </TitleContainer>
                <DetailContainer>
                    <p>Une photo ou du texte</p>
                </DetailContainer>
            </InformationContainer> */}

        </MainContainer>
    )
}

export default HomePage