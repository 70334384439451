import React from "react"
import { BrowserRouter as Router, Route, Routes} from "react-router-dom"
import Error from "./layouts/Error"
import Header from "./layouts/Header"
import Footer from "./layouts/Footer"
import GlobalStyle from "./utils/style/GlobalStyle"
import HomePage from "./pages/HomePage"
import BookingPage from "./pages/BookingPage"
import ConnexionPage from "./pages/ConnexionPage"
import GalleryPage from "./pages/GalleryPage"
import PricesPage from "./pages/PricesPage"
import CalendarPage from "./pages/CalendarPage"

function App() {

  return (
    <Router>
      <GlobalStyle />
      <Header />
      <Routes>
        <Route path='/' element={<HomePage />}/>
        <Route path='/booking' element={<BookingPage />}/>
        <Route path='/gallery' element={<GalleryPage />}/>
        <Route path='/prices' element={<PricesPage />}/>
        <Route path='/connexion' element={<ConnexionPage  />}/>
        <Route path='/calendar' element={<CalendarPage/>}/>
        <Route path='*' element={<Error />}/>
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
