import axios from "axios"
import { useState, useEffect } from "react"
import { API_ROUTES } from "../utils/constants"
import styled from "styled-components"
import { colors, MediaMobile } from "../utils/style/GlobalStyle"

// #region CSS
const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    gap: 10px;
`

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & div {
        font-size: 14px;
    }
`

const Button = styled.label`
    position: relative;
    border-radius: 5px;
    background-color: ${colors.backgroundThree};
    border: none;
    padding: 5px 15px;
    font-size: 14px;
    &: hover{
        background-color: ${colors.background};
        color: ${colors.primary};
        cursor: pointer;
    }
    &.selected {
        background-color: ${colors.background};
        color: ${colors.primary};
    }
    ${MediaMobile} {
        font-size: 12px;
    }
`

const Input = styled.input`
    position: absolute;
    opacity: 0;
`
// #endregion CSS

function PrestationList ({name, onChange, selectedObjects, setSelectedObjects}) {
    const [inputList, setInputList] = useState([])
    
    // Récupération des données
    const getFetchData = async () => {
        try {
            const getElement = await axios.get(API_ROUTES.PRICES)
            setInputList(getElement.data)
        } catch (err) {
            console.error(err)
        }
    }

    // Gestion du bouton "choisir"
    const handleSelectProduct = (e) => {
        const selectedObject = inputList.find((i) => i.title === e.target.value)
        if (!selectedObject) {
            console.error("Selected object not found")
            return
        }
        const newValue = {
            title: selectedObject.title,
            time: selectedObject.time
          }
        setSelectedObjects(newValue)
        onChange(newValue)
    }

    useEffect(() => {
        getFetchData()
    }, [])
    
    return(
        <MainContainer>
            {inputList.map((item, index) => 
            <Container>
                <div 
                    key={`title-${item}-${index}`} 
                    >
                    {item.title}
                </div>
                <Button 
                    className={selectedObjects && selectedObjects.title === item.title ? "selected" : "" }
                    >
                    choisir
                    <Input 
                        type="radio" 
                        name={name.title} 
                        value={item.title} 
                        onChange={handleSelectProduct}
                    />
                </Button>
            </Container>
            )}
        </MainContainer>
    )
}

export default PrestationList