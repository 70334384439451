import styled from "styled-components"
import { colors, MediaMobile } from "../utils/style/GlobalStyle"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons"

// #region CSS
const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    ${MediaMobile} {
        padding: 10px;
    }

`

const BookingLink = styled(Link)`
    background-color: ${colors.button};
    color: ${colors.primary};
    text-decoration: none;
    border-radius: 5px;
    padding: 16px 50px;
    ${MediaMobile} {
        border-radius: 50%;
        padding: 12px 15px;
    }
    & p {
        margin: 0;
        display: block;
        ${MediaMobile} {
            display: none;
        }
    }
    & div {
        display: none;
        ${MediaMobile} {
            display: block;
        }
    }
`

const ImageContainer = styled(Link)`
    text-decoration: none;
`

const Imgage = styled.img`
    height: 52px;
    ${MediaMobile} {
        height: 40px;
    }
`

const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`

const FontIcon = styled(FontAwesomeIcon)`
    color: ${colors.tertiary};
    font-size: 20px;
`
// #endregion CSS

function Header() {
    return(
        <MainContainer>
            <BookingLink to="/booking">
                <p>Rendez-vous</p>
                <div><FontAwesomeIcon icon={faCalendarDays} /></div>
            </BookingLink>
            <ImageContainer to="/">
                <Imgage src={`${process.env.PUBLIC_URL}/assets/logo-volteface.png`} alt="logo-voltface" />
            </ImageContainer>
            <IconContainer>
                <a href="https://www.instagram.com/volteface__/" aria-label="instagram" target="blank"><FontIcon icon={faInstagram} /></a>
                <a href="https://www.facebook.com/profile.php?id=100086431569687" aria-label="facebook" target="blank"><FontIcon icon={faFacebookF} /></a>
            </IconContainer>
        </MainContainer>
    )
}

export default Header